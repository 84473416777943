<template>
  <v-row
    no-gutters
  >
    <v-col
      id="leftCol"
      cols="12"
      md="6"
    >
<!-- eslint-disable -->
  <section class="spacing-playground pa-8 deep-purple darken-2">

    <div class=" pb-4 text-center text-h3 white--text ">Rosalie</div>
 
    <v-container>
        <v-carousel>
          <v-carousel-item
            v-for="(item,i) in Rosalie"
            :key="i"
            :src="item.pathLong"
            reverse-transition="fade"
            transition="fade"
          ></v-carousel-item>
        </v-carousel>
  </v-container>   
  </section>
</v-col>
    <v-col
      id="leftCol"
      cols="12"
      md="6"
    >
<!-- eslint-disable -->
  <section class="spacing-playground pa-8 deep-purple darken-2">

    <div class=" pb-4 text-center text-h3 white--text ">Dixon Street</div>
 
    <v-container>
        <v-carousel>
          <v-carousel-item
            v-for="(item,i) in Dixon"
            :key="i"
            :src="item.pathLong"
            reverse-transition="fade"
            transition="fade"
          ></v-carousel-item>
        </v-carousel>
  </v-container>   
  </section>
</v-col>
</v-row>
</template>

<script>
  export default {
    data () {
      return {
        Rosalie: [],
        Dixon: [],
      }
    },
    mounted () {
      this.importAll(require.context('@/assets/Rosalie/', true, /\.jpg$/))
      this.importDixonPhotos(require.context('@/assets/Dixon/', true, /\.jpg$/))
    },
    methods: {
      importAll (r) {
        r.keys().forEach(key => (this.Rosalie.push({ pathLong: r(key), pathShort: key })))
      },
      importDixonPhotos (importedPhotos) {
        importedPhotos.keys().forEach(key => (this.Dixon.push({ pathLong: importedPhotos(key), pathShort: key })))
      },
    },
  }
</script>
